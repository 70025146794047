import type { AppDispatch } from '@/store';

import { resetFilters, setFilter } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';

export const removeMultipleVoyageFilter = () => async (_dispatch: AppDispatch) => {
  resetFilters(FILTER_KEY.voyageId);
};

export const setMultipleVoyageFilter = (voyageId: Array<string> | string) => async (_dispatch: AppDispatch) => {
  setFilter.voyageId(voyageId);
};
