import React, { memo, useMemo, useState } from 'react';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';

import Location from '@/components/Icon/Location';
import Ship from '@/components/Icon/Ship';
import ShipSideView from '@/components/Icon/ShipSideView';
import UIResource from '@/components/UIResource';
import WithFrenchContent from '@/components/WithFrenchContent';
import { getShipNames } from '@/helpers/util';

import ImageVideo from '../ImageVideo/ImageVideo';
import PackagePrice from '../PackagePrice/PackagePrice';

import './PackageCardMedia.scss';

type Props = {
  fullCruiseButtonSlot: React.ReactNode;
  index: number;
  packageData: FilteredPackage;
};

const PackageCardMedia = ({ fullCruiseButtonSlot, index, packageData }: Props) => {
  const [isImageHover, toggleImageHover] = useState(false);

  const sailing = packageData?.bestPriceSailing;
  const shipNames = useMemo(() => getShipNames(packageData), [packageData]);

  return (
    <div
      className="PackageCard__media"
      onMouseLeave={() => toggleImageHover(false)}
      onMouseOver={() => toggleImageHover(true)}
    >
      <div className="mediaImg">
        <ImageVideo
          imgSrc={packageData?.landscapeHero?.src ?? ''}
          isImageHover={isImageHover}
          priority={index === 0 || index === 1}
          videoSrc={packageData?.sizzleReel?.src}
        />
      </div>
      <div className="mediaText">
        <div className="mediaText__top">
          <WithFrenchContent className="packageName" node="div" text={packageData.packageName} />
          <div className="portsOfCalls">
            <div className="portLocationIcon">
              <Location />
            </div>
            <div className="ports">
              {sailing?.ports?.map((port, index) => {
                return (
                  <React.Fragment key={index}>
                    <WithFrenchContent text={port.name} />
                    {index < sailing.ports.length - 1 && <span> • </span>}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mediaText__bottom">
          <PackagePrice customUpliftIcon packageData={packageData} />
          <div className="shipNameDuration">
            <div className="duration">
              <span className="durationIcon">
                <ShipSideView />
              </span>
              <span className="durationNumber">
                <UIResource
                  id="VoyageCalendar.numNights"
                  values={{
                    durationNum: packageData.duration,
                    nightStr: <UIResource id="VoyageCalendar.night" />,
                    nightsStr: <UIResource id="VoyageCalendar.nights" />,
                  }}
                />
              </span>
            </div>
            <div className="shipNameIcon">
              <span className="shipIcon">
                <Ship />
              </span>
              <span className="shipName">{shipNames}</span>
            </div>
          </div>
          <div className="fullCruiseBtn">{fullCruiseButtonSlot}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(PackageCardMedia);
