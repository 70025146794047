import React, { useCallback, useId, useMemo, useRef, useState } from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';
import cn from 'classnames';

import ChevronIcon from '@publicImages/svg/chevron-down.svg?icon';

import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';
import ReactRemoveScrollBarAndroidFix from '@/helpers/ReactRemoveScrollBarAndroidFix';
import useKeyboardFocus from '@/hooks/useKeyboardFocus';
import useScrollUnlockRadix from '@/hooks/useScrollUnlockRadix';

import styles from './Select.module.scss';

const getLabel = (label: string | string[]) => (Array.isArray(label) ? label[0] : label);

type SelectProps = {
  'aria-label'?: string;
  className?: string;
  dropdownClassName?: string;
  icon?: React.ReactNode;
  options: readonly {
    enabled: boolean;
    label: [string] | React.ReactNode | string;
    value: string;
  }[];
  size?: 'default' | 'small';
} & SelectPrimitive.SelectProps;

const Select = ({
  'aria-label': ariaLabel,
  className,
  dropdownClassName,
  icon,
  options,
  size,
  ...props
}: SelectProps) => {
  const { defaultValue, value } = props;
  const [isOpen, setIsOpen] = useState(false);

  const id = useId();
  const ariaLabelId = `${id}Label`;
  const isNotDefaultValue = value !== defaultValue;
  const isByKeyboard = useKeyboardFocus();
  const contentRef = useRef<HTMLDivElement>();

  const { removeUnlockScroll, unlockScroll } = useScrollUnlockRadix();
  const androidFixer = useMemo(() => ReactRemoveScrollBarAndroidFix.tryCreate(), []);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      if (isOpen) {
        androidFixer?.apply();
        unlockScroll();
      } else {
        androidFixer?.reset();
        removeUnlockScroll();
      }
    },
    [androidFixer, unlockScroll, removeUnlockScroll],
  );

  useFocusTrap({
    element: contentRef.current!,
    isOpened: isOpen,
    nodeSelector: '[role="option"]',
  });

  const valueLabel = options.find((option) => option.value === value)?.label;

  return (
    <SelectPrimitive.Root {...props} onOpenChange={onOpenChange}>
      <SelectPrimitive.Group>
        {ariaLabel && (
          <label className={styles.hiddenLabel} htmlFor={id} id={ariaLabelId}>
            {ariaLabel}
          </label>
        )}
        <SelectPrimitive.Trigger
          aria-haspopup="true"
          aria-labelledby={ariaLabelId}
          className={cn(styles.root, className, {
            _isApplied: isNotDefaultValue,
            _smallPill: size === 'small',
            [styles.keyboardFocus]: isByKeyboard,
          })}
          id={id}
          tabIndex={0}
        >
          {icon && <SelectPrimitive.Icon>{icon}</SelectPrimitive.Icon>}

          <span className={styles.value}>
            <span aria-live="polite" role="status">
              <span className="sr-only">current value</span> {valueLabel}
            </span>
          </span>

          <SelectPrimitive.Icon className={styles.dropdownIndicator}>
            <ChevronIcon />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cn(styles.dropdown, dropdownClassName)}
            position="popper"
            ref={contentRef}
          >
            <SelectPrimitive.Viewport>
              {options
                .filter((option) => option.enabled)
                .map((option) => (
                  <SelectPrimitive.Item
                    aria-current={value === option.value}
                    className={cn(styles.option, {
                      [styles.isSelected]: value === option.value,
                      [styles.keyboardFocus]: isByKeyboard,
                    })}
                    key={option.value}
                    value={option.value}
                  >
                    <SelectPrimitive.ItemText>{getLabel(option.label)}</SelectPrimitive.ItemText>
                  </SelectPrimitive.Item>
                ))}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Group>
    </SelectPrimitive.Root>
  );
};

export default React.memo(Select);
