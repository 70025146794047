'use client';

import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import React, { useCallback } from 'react';

import type { TUIResourceId } from '@/ducks/common/resources';

import UIResource from '@/components/UIResource';
import { useQuickSearchItems } from '@/ducks/quickSearchItems/hooks';
import { env } from '@/environment';
import formatMoney from '@/helpers/util/formatMoney';
import tagmanager from '@/tagmanager';

import { QuickSearchCard } from './Card';
import { QuickSearchCardsSkeleton } from './skeleton';

const mergeURLWithPreviousData = (pathname: string, url: string, params: ReadonlyURLSearchParams | null) => {
  const newParams = new URLSearchParams(url.split('?')[1]);
  const paramsToPreserve = [
    'reservationNumber',
    'channelID',
    'agencyId',
    'agentId',
    'selectedPackages',
    'selectedRegionsIds',
  ];

  paramsToPreserve.forEach((key) => {
    params?.getAll(key).forEach((value) => newParams.append(key, value));
  });

  return `${env.CONTEXT}${pathname}?${newParams}`;
};

export const QuickSearchCards = () => {
  const { isLoaded, quickSearchItems } = useQuickSearchItems();

  const pathname = usePathname();
  const params = useSearchParams();

  const onCardClick = useCallback((region: string) => {
    tagmanager.tracker.blackFriday.onAdvertCardClick(region);
  }, []);

  if (!isLoaded) {
    return <QuickSearchCardsSkeleton />;
  }

  return (
    <>
      {quickSearchItems?.map(({ currencyCode, name, position, price, priceOld, url }) => {
        const formattedPrice = formatMoney(price, currencyCode);
        const formattedOldPrice = priceOld ? formatMoney(priceOld, currencyCode) : '';

        return (
          <QuickSearchCard
            key={name}
            onClick={onCardClick}
            price={formattedPrice!}
            priceOld={formattedOldPrice}
            priceType="per cabin"
            title={<UIResource id={`QuickSearchPosition${position}.heading` as TUIResourceId} />}
            url={mergeURLWithPreviousData(pathname, url, params)}
          />
        );
      })}
    </>
  );
};
