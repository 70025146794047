import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import { Skeleton } from '@/components/Skeleton';
import UIResource from '@/components/UIResource';
import { selectDefaultPriceRange } from '@/ducks/filters/defaultValuesSelectors';
import { selectPriceLabel } from '@/ducks/filters/labels/price';
import { selectCurrencyCode, selectMaxPrice, selectMinPrice, selectPriceType } from '@/ducks/filters/selectors';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';
import { selectFilteredPackages } from '@/ducks/pages/chooseVoyage/selectors';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { FiltersPriceType } from '@/infra/types/common/filters';
import tagmanager from '@/tagmanager';

import { BoxCheckbox, TextField } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

export const INPUT_NUM_REGEXP = /^[0-9]+$/;

class PriceRangeFilter extends BaseFilter {
  handleChange = (priceType) => {
    const { filteredPackages, priceType: prevPriceType, trackPriceType } = this.props;
    if (priceType !== prevPriceType || filteredPackages.length === 0) {
      setFilters({ [FILTER_KEY.priceMax]: null, [FILTER_KEY.priceMin]: null, [FILTER_KEY.priceType]: priceType });
      trackPriceType({ module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS, priceType });
    }
  };

  onMaxPriceChange = (event) => {
    this.onPriceChange('max', event.target.value);
  };

  onMinPriceChange = (event) => {
    this.onPriceChange('min', event.target.value);
  };

  onPriceChange = (type, value) => {
    if (value && !INPUT_NUM_REGEXP.test(value)) {
      return;
    }

    const { defaultMaxPrice, maxPrice, minPrice, trackPriceRange } = this.props;

    const selectedPrice =
      type === 'min'
        ? { priceMax: maxPrice || defaultMaxPrice, priceMin: value }
        : { priceMax: value, priceMin: minPrice };

    setFilters(selectedPrice);
    trackPriceRange(selectedPrice);
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { formatMessage } = this.context;
    const {
      appliedCurrency,
      defaultPriceRange,
      isOpenFilterSection,
      isPackagesLoading,
      maxPrice,
      minPrice,
      priceType,
    } = this.props;

    const label = isPackagesLoading ? skeletonLabel : this.props.label;

    return (
      <div className="AF__row PriceRangeFilter__main">
        <AdvancedFilterHOC
          defaultValue={label}
          filterName={formatMessage({
            defaultMessage: 'Price Range {n}',
            id: 'AdvancedFilter.PriceRange',
            values: {
              n: getSymbolFromCurrenciesData(appliedCurrency),
            },
          })}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="action priceRange">
            <div className="row">
              <div className="minPrice">
                <TextField
                  defaultValue={defaultPriceRange.minPrice}
                  form="priceRangeForm"
                  id="minPrice"
                  inputmode="numeric"
                  label={<UIResource id="AdvancedFilter.PriceRange.min.title" />}
                  labelClassname={'minPrice__label'}
                  name="minPrice"
                  onChange={this.onMinPriceChange}
                  pattern={INPUT_NUM_REGEXP.source}
                  tabIndex="0"
                  value={minPrice || defaultPriceRange.minPrice}
                />
              </div>
              <p className="to">
                <FormattedMessage defaultMessage="To" id="" />
              </p>
              <div className="maxPrice">
                <TextField
                  defaultValue={defaultPriceRange.maxPrice}
                  form="priceRangeForm"
                  id="maxPrice"
                  inputmode="numeric"
                  label={<UIResource id="AdvancedFilter.PriceRange.max.title" />}
                  labelClassname={'maxPrice__label'}
                  name="maxPrice"
                  onChange={this.onMaxPriceChange}
                  pattern={INPUT_NUM_REGEXP.source}
                  tabIndex="0"
                  value={maxPrice || defaultPriceRange.maxPrice}
                />
              </div>
              <form hidden id="priceRangeForm">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleFilterSectionState();
                  }}
                  tabIndex={-1}
                  type="submit"
                />
              </form>
            </div>
            <div className="priceperDiv">
              <FormattedMessage defaultMessage="Price per:" id="AdvancedFilter.priceType.Priceper" />
            </div>
            <div className="perSailorDiv">
              <BoxCheckbox
                checked={priceType === FiltersPriceType.perCabin}
                name={formatMessage({
                  defaultMessage: 'Cabin',
                  id: 'AdvancedFilter.priceType.label.cabin',
                })}
                onBoxClick={() => this.handleChange(FiltersPriceType.perCabin)}
              />
              <BoxCheckbox
                checked={priceType === FiltersPriceType.perSailor}
                name={formatMessage({
                  defaultMessage: 'Sailor',
                  id: 'AdvancedFilter.priceType.label.sailor',
                })}
                onBoxClick={() => this.handleChange(FiltersPriceType.perSailor)}
              />
              <BoxCheckbox
                checked={priceType === FiltersPriceType.sailorPerNight}
                name={formatMessage({
                  defaultMessage: 'Sailor/night',
                  id: 'AdvancedFilter.priceType.label.sailorPerNight',
                })}
                onBoxClick={() => this.handleChange(FiltersPriceType.sailorPerNight)}
              />
            </div>
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }
}

PriceRangeFilter.propTypes = {
  appliedCurrency: PropTypes.string.isRequired,
  filteredPackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  maxPrice: PropTypes.number.isRequired,
  minPrice: PropTypes.number.isRequired,
  priceType: PropTypes.string.isRequired,
  sailors: PropTypes.number.isRequired,
  trackPriceRange: PropTypes.func,
  trackPriceType: PropTypes.func,
};

PriceRangeFilter.defaultProps = {
  isOpenFilterSection: false,
  trackPriceRange: () => {},
  trackPriceType: () => {},
};

PriceRangeFilter.contextType = FormattedMessageContext;

const mapStateToProps = (state) => {
  return {
    appliedCurrency: selectCurrencyCode(state),
    defaultPriceRange: selectDefaultPriceRange(state),
    filteredPackages: selectFilteredPackages(state),
    isPackagesLoading: selectIsFilteringLoading(state),
    label: selectPriceLabel(state),
    maxPrice: selectMaxPrice(state),
    minPrice: selectMinPrice(state),
    priceType: selectPriceType(state),
  };
};

const mapDispatchToProps = {
  trackPriceRange: tracking.trackPriceRange,
  trackPriceType: tracking.trackPriceType,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceRangeFilter);

const skeletonLabel = <Skeleton width={100} />;
