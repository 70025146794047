import Link from 'next/link';

import cn from 'classnames';

import BestRateIcon from '@publicImages/svg/best-rate.svg?icon';
import SailorCardCalendar from '@publicImages/svg/sailor-card-calendar.svg?icon';

import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import UIResource from '@/components/UIResource';
import { Price } from '@/components/elements';
import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import useBlackFridayIsEnabled from '@/ducks/programs/blackFriday/hooks/useBlackFridayIsEnabled';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { formatDate } from '@/helpers/util/dateUtil';
import usePrecheckoutUrlWithParams from '@/hooks/usePrecheckoutPath';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import './SailingCard.scss';
type Props = {
  isBestPriceSailing?: boolean;
  onSailingClick?: (sailing: SailingFromPackages) => void;
  sailing: SailingFromPackages;
};

const SailingCard = ({ isBestPriceSailing, onSailingClick, sailing }: Props) => {
  const cabins = useAppSelector(selectCabins) ?? 1;
  const priceType = useAppSelector(selectPriceType);
  const sailors = useAppSelector(selectSailors) ?? 2;

  const currencyCode = sailing.startingPrice.currencyCode ?? 'USD';
  const sailingPrice =
    priceType === FiltersPriceType.sailorPerNight
      ? Math.ceil(getAmountPerVoyage(sailing.startingPrice, sailing.duration, { sailors }))
      : getAmountPerItem(sailing.startingPrice, { cabins, priceType, sailors });

  const isBlackFridayEnabled = useBlackFridayIsEnabled();

  const classes = cn('SailingCard', {
    blackFridaySailingCard: isBlackFridayEnabled,
  });

  const urlPath = usePrecheckoutUrlWithParams(sailing);

  return (
    <Link
      className={classes}
      data-id={sailing?.id}
      href={urlPath}
      onClick={() => onSailingClick?.(sailing)}
      prefetch={false}
      role="button"
      tabIndex={0}
    >
      <div className="iconYear">
        <SailorCardCalendar /> {formatDate(sailing?.startDate, 'yyyy')}
        {isBestPriceSailing && (
          <>
            <span className="bestRateText">
              <UIResource id="Itinerary.BestRate.label" />
            </span>
            <span className="bestRateIcon">
              <BestRateIcon />
            </span>
          </>
        )}
      </div>
      <div className="startEndDate">
        {formatDate(sailing?.startDate, 'E, MMM dd')}
        <span className="endDate">
          {' - '}
          {formatDate(sailing?.endDate, 'E, MMM dd')}
        </span>
      </div>
      <span className="priceFromLabel">
        <UIResource id="Cabins.Lead.Price.label.from" />{' '}
      </span>
      <span className="price">
        <Price amount={sailingPrice} currencyCode={currencyCode} />
      </span>
    </Link>
  );
};

export default SailingCard;
