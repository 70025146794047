import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import type { TPromoBannerMerchandisingProps } from '@/ducks/promoBanners/types';

import { selectMerchandisingBannerDetails } from '@/ducks/promoBanners/selectors';

export enum MerchandisingBannerComponents {
  exitInterstitial = 'exitInterstitial',
  promoBox = 'preCheckoutModuleToggle',
  sailingItem = 'sailingItemToggle',
  voyageCardBarTab = 'voyageCardBarTab',
  voyageCardMerchandising = 'voyageCardMerchandising',
  countdownClock = 'countdownClock',
}

const checkComponentVisibility = (component: string, promoInfo: TPromoBannerMerchandisingProps) => {
  if (!promoInfo) return false;

  const {
    countdownClockToggle,
    exitInterstitial,
    preCheckoutModuleToggle,
    sailingItemToggle,
    voyageCardBarTab,
    voyageCardMerchandising,
  } = promoInfo;
  switch (component) {
    case MerchandisingBannerComponents.promoBox:
      return preCheckoutModuleToggle;
    case MerchandisingBannerComponents.sailingItem:
      return sailingItemToggle;
    case MerchandisingBannerComponents.exitInterstitial:
      return exitInterstitial;
    case MerchandisingBannerComponents.voyageCardBarTab:
      return voyageCardBarTab;
    case MerchandisingBannerComponents.voyageCardMerchandising:
      return voyageCardMerchandising;
    case MerchandisingBannerComponents.countdownClock:
      return countdownClockToggle;
    default:
      return false;
  }
};

type Sailing = {
  endDate: string;
  id: string;
  startDate: string;
};

const useIsPromoActive = (sailingData: Sailing | Sailing[] | undefined, component: string): boolean => {
  const promoInfo = useSelector(selectMerchandisingBannerDetails) as TPromoBannerMerchandisingProps;
  return useMemo(() => {
    if (!checkComponentVisibility(component, promoInfo)) {
      return false;
    }

    if (!isEmpty(sailingData) && promoInfo) {
      const { sailingsToBeExcluded } = promoInfo;
      const sailings = Array.isArray(sailingData) ? sailingData : [sailingData];
      const involvedSailings = sailings.filter((sailing) => sailingsToBeExcluded?.indexOf(sailing?.id) === -1);
      if (promoInfo?.voyageStart || promoInfo?.voyageEnd) {
        const start = promoInfo?.voyageStart ? new Date(promoInfo?.voyageStart) : undefined;
        const end = promoInfo?.voyageEnd ? new Date(promoInfo?.voyageEnd) : undefined;
        return involvedSailings?.some(
          ({ endDate, startDate }) =>
            (!start || new Date(startDate) >= new Date(start)) && (!end || new Date(endDate) <= new Date(end)),
        );
      }
      return !!involvedSailings?.length;
    }
    return true;
  }, [promoInfo, sailingData]);
};

export default useIsPromoActive;
