import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { updateQuickSearchItemsAction } from '@/ducks/quickSearchItems/actions';
import { selectQuickSearchItems, selectQuickSearchItemsIsLoaded } from '@/ducks/quickSearchItems/selectors';
import { useAppDispatch, useAppSelector } from '@/store';

export const useQuickSearchItems = () => {
  const apolloClient = useApolloClient();
  const dispatch = useAppDispatch();

  const currencyCode = useAppSelector(selectCurrencyCode);
  const sailors = useAppSelector(selectSailors);

  useEffect(() => {
    dispatch(updateQuickSearchItemsAction(apolloClient, currencyCode, sailors));
  }, [apolloClient, currencyCode, sailors, dispatch]);

  const isLoaded = useAppSelector(selectQuickSearchItemsIsLoaded(currencyCode));
  const quickSearchItems = useAppSelector(selectQuickSearchItems(currencyCode));

  return {
    isLoaded,
    quickSearchItems,
  };
};
