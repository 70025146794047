import type { AppDispatch } from '@/store';

import { resetFmFlow } from '@/ducks/fm/reset';
import { removeSearchParamsExcept } from '@/ducks/routes/history';

export const resetMnvvSearchCriteria = () => (_dispatch: AppDispatch) => {
  removeSearchParamsExcept(['reservationNumber', 'channelID', 'agentId', 'agencyId', 'bookingChannel']);
};

export const resetMnvvFlow = () => (dispatch: AppDispatch) => {
  dispatch(resetFmFlow(['reservationNumber', 'channelID']));
};
