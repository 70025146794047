import React, { useCallback, useMemo } from 'react';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { withSaleBadge } from '@/components/BlackFriday/Badges/PackageCard';
import PromoBannerPlace from '@/components/PromoBanner/Place';
import { trackVoyageView } from '@/ducks/pages/chooseVoyage/tracking';
import { PromoBannerPlaceId } from '@/ducks/promoBanners/types';
import { type AppDispatch, useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';
import { withTrackAppearance } from '@/tagmanager/hocs';

import PackageCard from '../PackageCard/PackageCard';
import PromotionBannerVariator from '../PromotionBannerCard/PromotionBannerVariants';
import ZeroResults from '../ZeroResults';

const {
  tracker: {
    ECommercePromotions: { measuringProductClicks },
    common: { trackButtonClick },
    createTracker,
  },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({
  location: trackerConstants.LISTS.ITINERARY_RESULTS,
});

const getSailingClickTracker = (dispatch: AppDispatch, labels: string[]) => (sailing: SailingFromPackages) => {
  dispatch(
    measuringProductClicks({
      labels,
      list: trackerConstants.LISTS.ITINERARY_RESULTS,
      sailing,
    }),
  );
  trackEvent({
    action: trackerConstants.EVENT_ACTIONS.LINK_CLICK,
    label: trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.NEXT_SAILING_ITEM,
  });
};

const trackFullCruiseButtonClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
  trackButtonClick({
    labels: [event.currentTarget?.innerText],
  });
};

type Props = {
  packages: FilteredPackage[];
};

const PackageCardList = ({ packages }: Props) => {
  const dispatch = useAppDispatch();

  const isSinglePackage = packages.length === 1;
  const promotionBannerAfterIndex = useMemo(() => Math.min(1, packages.length - 1), [packages.length]);

  const renderPackage = useCallback(
    (packageData: FilteredPackage, index: number) => {
      const TrackedPackageCard = withTrackAppearance({
        Component: withSaleBadge(PackageCard),
        onAppearanceAction: trackVoyageView({
          list: trackerConstants.LISTS.ITINERARY_RESULTS,
          packagesData: [packageData],
        }),
        oncePerPage: true,
      });

      const trackViewMoreDatesClick = () => {
        trackEvent({
          action: trackerConstants.EVENT_ACTIONS.LINK_CLICK,
          label: `view-${packageData.sortedSailings.length}-dates`,
        });
      };

      return (
        <TrackedPackageCard
          index={index}
          key={packageData?.packageCode}
          onFullCruiseButtonClick={trackFullCruiseButtonClick}
          onSailingClick={getSailingClickTracker(dispatch, [trackerConstants.LISTS.ITINERARY_RESULTS])}
          onSailingFromViewMoreDatesClick={getSailingClickTracker(dispatch, [
            trackerConstants.FLYOUTS.ITINERARY_RESULTS,
          ])}
          onViewMoreDatesClick={trackViewMoreDatesClick}
          packageData={packageData}
        />
      );
    },
    [dispatch],
  );

  const renderZeroResultsPackage = useCallback(
    (packageData: FilteredPackage) => {
      const TrackedPackageCard = withTrackAppearance({
        Component: PackageCard,
        onAppearanceAction: trackVoyageView({
          list: trackerConstants.LISTS.ZERO_RESULTS,
          packagesData: [packageData],
        }),
        oncePerPage: true,
      });

      const trackZeroResultClick = (sailing: SailingFromPackages) => {
        dispatch(
          measuringProductClicks({
            labels: [trackerConstants.EVENT_LABELS.ZERO_RESULTS],
            list: trackerConstants.LISTS.ZERO_RESULTS,
            sailing,
          }),
        );
      };

      const trackViewMoreDatesClick = () => {
        trackEvent({
          action: trackerConstants.EVENT_ACTIONS.LINK_CLICK,
          label: `view-${packageData.sortedSailings.length}-dates`,
        });
      };

      return (
        <TrackedPackageCard
          key={packageData?.packageCode}
          onFullCruiseButtonClick={trackFullCruiseButtonClick}
          onSailingClick={trackZeroResultClick}
          onSailingFromViewMoreDatesClick={trackZeroResultClick}
          onViewMoreDatesClick={trackViewMoreDatesClick}
          packageData={packageData}
        />
      );
    },
    [dispatch],
  );

  if (packages.length === 0) {
    return <ZeroResults renderPackage={renderZeroResultsPackage} />;
  }

  return (
    <>
      {packages?.map((packageData, index) => (
        <React.Fragment key={packageData?.packageCode}>
          {renderPackage(packageData, index)}
          {index === promotionBannerAfterIndex && (
            <>
              <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsC} />
              <PromotionBannerVariator isSinglePackage={isSinglePackage} />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default React.memo(PackageCardList);
