import { type ComponentProps } from 'react';

import { selectWarnings } from '@/features/warnings/selectors';
import { type TWarning, WarningTarget } from '@/features/warnings/types';
import { useAppSelector } from '@/store';

import WarningBoxView from './View';

export { WarningTarget };

type TProps = Omit<ComponentProps<typeof WarningBoxView>, keyof TWarning> & {
  target: WarningTarget;
};

const WarningBox = ({ target, ...props }: TProps) => {
  const warning = useAppSelector(selectWarnings)?.[target];
  if (warning) return <WarningBoxView {...props} {...warning} />;
};

export default WarningBox;
