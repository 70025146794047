import PropTypes from 'prop-types';
import React from 'react';

import { getMonth, getYear, lastDayOfMonth } from 'date-fns';
import noop from 'lodash/noop';
import { connect } from 'react-redux';

import DatePicker from '@/components/DatePicker';
import Calendar from '@/components/Icon/Calendar';
import Filter from '@/components/elements/Filter';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { resetFilters, setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';
import { getDefaultFilters, selectDefaultMaxDate, selectDefaultMinDate } from '@/ducks/filtersOptions/selectors';
import { selectMnvvVoyage } from '@/ducks/mnvv/selectors';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { removeMultipleVoyageFilter } from '@/ducks/multipleVoyageFilter';
import { selectIsMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectors';
import { isServerSide } from '@/helpers/isServerSide';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';
import { completeMonthLabel, format, parse } from '@/helpers/util/dateUtil';
import { trackResetFilters } from '@/helpers/util/tracking';
import withSearchParams from '@/hocs/withSearchParams';

import './DateRefinement.scss';

class DateRefinement extends React.Component {
  getPillLabelForChooseVoyageNew = () => {
    const { appliedEndDate, appliedStartDate, hideTitle } = this.props;
    const clNames = !hideTitle ? 'refinementText' : 'highlight';
    const startMonth = completeMonthLabel(appliedStartDate);
    const endMonth = completeMonthLabel(appliedEndDate);

    if (!startMonth || !endMonth) {
      return <></>;
    }
    return (
      <span className={clNames} suppressHydrationWarning>
        {startMonth}
        {' - '}
        {endMonth}
      </span>
    );
  };

  getPillState = () => {
    const { appliedEndDate, appliedStartDate, defaultEndDate, defaultStartDate, filterOpened } = this.props;
    const { isMobile } = this.state;
    const parentClass = isServerSide() ? null : document.getElementById('primaryContainer');
    if (filterOpened) {
      if (parentClass) {
        parentClass.classList.add('lightBorder');
      }
      return 'open';
    }
    if (!filterOpened) {
      if (parentClass) {
        parentClass.classList.remove('lightBorder');
      }
    }

    if (isMobile) {
      return 'arrow';
    }

    return appliedStartDate !== defaultStartDate || appliedEndDate !== defaultEndDate ? 'applied' : 'default';
  };

  handleResetIconClick = () => {
    resetFilters([FILTER_KEY.dateFrom, FILTER_KEY.dateTo, FILTER_KEY.voyageIds]);
  };

  isApplyDisabled = () => {
    const { reset, selectedEnd, selectedStart } = this.state;
    const { appliedEndDate, appliedStartDate, isMultipleVoyageFilter } = this.props;

    if (reset && isMultipleVoyageFilter) {
      return false;
    }
    return selectedStart === appliedStartDate && selectedEnd === appliedEndDate;
  };

  onDismiss = () => {};

  onRenderChange = (isFilterOpen) => {
    const { appliedEndDate, appliedStartDate, onRenderChange, setFilterOpened } = this.props;
    onRenderChange?.(isFilterOpen);
    setFilterOpened?.(isFilterOpen);
    this.setState({
      reset: false,
      selectedEnd: appliedEndDate,
      selectedStart: appliedStartDate,
    });
  };

  onSelectMonth = (start, end) => {
    this.setState({
      reset: false,
      selectedEnd: end,
      selectedStart: start,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { maxDate, reset, selectedEnd, selectedStart } = this.state;
    const { searchParams } = this.props;
    const { isMNVV } = getMnvvReservation(searchParams);

    const maxTime = parse(maxDate);
    const startTime = parse(selectedStart);
    const endTime = selectedEnd ? parse(selectedEnd) : lastDayOfMonth(startTime);

    const isSameYear = getYear(startTime) === getYear(maxTime) || getYear(startTime) === getYear(maxTime);
    const isSameMonth = getMonth(startTime) === getMonth(maxTime) || getMonth(endTime) === getMonth(maxTime);

    const isMNVVAllowed = isMNVV && isSameMonth && isSameYear;
    const endDate = isMNVVAllowed ? maxDate : selectedEnd || format(endTime);

    setFilters({
      [FILTER_KEY.dateFrom]: selectedStart,
      [FILTER_KEY.dateTo]: endDate,
      ...(reset ? { [FILTER_KEY.voyageIds]: null } : undefined),
    });
  };

  resetSelectedDates = () => {
    const { defaultEndDate, defaultStartDate } = this.props;
    this.setState({
      reset: true,
      selectedEnd: defaultEndDate,
      selectedStart: defaultStartDate,
    });

    trackResetFilters('date-range');
  };

  shResetDisplay = () => {
    const { appliedEndDate, appliedStartDate, defaultEndDate, defaultStartDate, isMultipleVoyageFilter } = this.props;
    if (isMultipleVoyageFilter) {
      return false;
    }
    const noDateSelection = appliedStartDate === undefined && appliedEndDate === undefined;
    if (noDateSelection) {
      return true;
    }
    if (!noDateSelection) {
      const isSelectedStartDateSameAsDefault = appliedStartDate === defaultStartDate;
      const isSelectedEndDateSameAsDefault = appliedEndDate === defaultEndDate;
      if (isSelectedStartDateSameAsDefault && isSelectedEndDateSameAsDefault) {
        return true;
      }
      return false;
    }
    return false;
  };

  constructor(props) {
    super(props);
    // Holds the selected date range locally, until submitted by the user
    this.state = {
      isMobile: false,
      reset: false,
      selectedEnd: null,
      selectedStart: null,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  async componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    if (window.innerWidth <= 576) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  }

  onEvent = (...args) => {
    const locator = '.DateRefinement.-newFilter .FilterPopover.-newFilter';
    scrollIntoViewAsync(locator, undefined, { block: 'nearest' }, 300);
    this.props.onEvent?.(...args);
  };

  render() {
    const { applyNewFilterUI, disabled, filterOpened, maxDate, minDate, renderMainFilter } = this.props;
    const { selectedEnd, selectedStart } = this.state;

    const pill = {
      disabled,
      headerIcon: <Calendar />,
      icon: !applyNewFilterUI && <Calendar />,
      label: this.getPillLabelForChooseVoyageNew(),
      onResetIconClick: this.handleResetIconClick,
      state: this.getPillState(),
      title: { defaultMessage: 'Travel Dates', id: 'DateRefinement.heading' },
      variant: 'large',
    };
    const popover = {
      ariaLabel: 'Select travel dates',
      bodyclass: '-refinementOpen',
      className: 'DateRefinement -newFilter',
      disabled,
      id: 'DateRefinement',
      isApplyDisabled: this.isApplyDisabled(),
      onDismiss: this.onDismiss,
      onRenderChange: this.onRenderChange,
      onReset: this.resetSelectedDates,
      onSubmit: this.onSubmit,
      open: filterOpened,
      renderMainFilter,
      shResetDisplay: this.shResetDisplay(),
      title: { defaultMessage: 'Travel Dates', id: 'DateRefinement.heading' },
    };
    return (
      <Filter
        applyNewFilterUI
        hasFocusTrap
        keepPopoverReferenceFocusAfterClose
        label="Date Options"
        onEvent={this.onEvent}
        pill={pill}
        popover={popover}
        position="top"
        showItineraries={false}
        tabIndex={0}
      >
        <DatePicker
          applyNewFilterUI={applyNewFilterUI}
          isShowHeader={false}
          maxDate={maxDate}
          minDate={minDate}
          onSelectMonth={this.onSelectMonth}
          rangeEnd={selectedEnd}
          rangeStart={selectedStart}
          resetSelectedDates={noop}
        />
      </Filter>
    );
  }
}

DateRefinement.propTypes = {
  appliedEndDate: PropTypes.string.isRequired,
  appliedStartDate: PropTypes.string.isRequired,
  applyNewFilterUI: PropTypes.bool,
  defaultEndDate: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  filterOpened: PropTypes.bool,
  hideTitle: PropTypes.bool,
  isMultipleVoyageFilter: PropTypes.bool,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  onEvent: PropTypes.func,
  onRenderChange: PropTypes.func,
  removeMultipleVoyageFilter: PropTypes.func.isRequired,
  renderMainFilter: PropTypes.func,
  setFilterDates: PropTypes.func.isRequired,
  setFilterOpened: PropTypes.func,
};
DateRefinement.defaultProps = {
  applyNewFilterUI: true,
  disabled: false,
  filterOpened: false,
  hideTitle: false,
  isMultipleVoyageFilter: false,
  onEvent: () => {},
  onRenderChange: () => {},
  renderMainFilter: () => {},
  setFilterOpened: () => {},
};

const mapStateToProps = (state, { searchParams }) => {
  const defaultFilters = getDefaultFilters(state);

  return {
    appliedEndDate: selectToDate(state),
    appliedStartDate: selectFromDate(state),
    defaultEndDate: defaultFilters.toDate,
    defaultStartDate: defaultFilters.fromDate,
    isMultipleVoyageFilter: selectIsMultipleVoyageFilterActive(state),
    maxDate: selectDefaultMaxDate(state, searchParams),
    minDate: selectDefaultMinDate(state),
    mnvvVoyage: selectMnvvVoyage(state),
  };
};

const mapDispatchToProps = {
  removeMultipleVoyageFilter,
};

export default withSearchParams(connect(mapStateToProps, mapDispatchToProps)(DateRefinement));
