import dynamic from 'next/dynamic';

import { PromotionTimer } from '@/components/PromoBanner/Merchandising/Timer';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { useAppSelector } from '@/store';

import useIsPromoActive, { MerchandisingBannerComponents } from './hooks/useIsPromoActive';

type BannerTypes = keyof typeof MAP_TO_COMPONENTS;
type ExtractProps<T> = T extends React.FC<infer P> ? P : never;
type ComponentPropsMap = {
  [Type in BannerTypes]: ExtractProps<(typeof MAP_TO_COMPONENTS)[Type]>;
};

type MerchandisingBannerProps = {
  [Type in BannerTypes]: {
    type: Type;
  } & ComponentPropsMap[Type];
}[BannerTypes];

export const MerchandisingBanner: React.FC<MerchandisingBannerProps> = (props) => {
  const { type, ...restProps } = props;

  const sailingData = useAppSelector(selectSailingData);
  const isPromoActive = useIsPromoActive(sailingData, type);

  if (!isPromoActive) {
    return null;
  }

  const Component = MAP_TO_COMPONENTS[type];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Component {...(restProps as any)} />;
};

const MAP_TO_COMPONENTS = {
  [MerchandisingBannerComponents.countdownClock]: dynamic(() => Promise.resolve(PromotionTimer)),
};
