import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';

import UIResource from '@/components/UIResource';
import { selectSelectedItineraries, selectSelectedPortsOfCall } from '@/ducks/filters/selectors';
import { useAppSelector } from '@/store';

import { type DestinationRegion, DestinationType, type DestinationsFilterData } from './types';

type ItemsIds = Record<string, string[]>;
export type AllItemsIds = Partial<Record<DestinationType, Record<string, string[]>>>;

export const useGetAppliedDestinationType = () => {
  const itineraries = useAppSelector(selectSelectedItineraries);
  const selectedPorts = useAppSelector(selectSelectedPortsOfCall);

  if (isEmpty(itineraries) && !isEmpty(selectedPorts)) {
    return DestinationType.PORTS_OF_CALL;
  }

  return DestinationType.ITINERARIES;
};

export const getSafeId = (id: string) => {
  return id.replace(/\s+/g, '-');
};

export const getCheckedItemsIds = (allItemsIds: AllItemsIds, regionId: string, destinationType: DestinationType) => {
  return allItemsIds[destinationType]?.[regionId] || [];
};

export const getAppliedItemsIds = (data?: DestinationsFilterData) => {
  const reducer = (ids: ItemsIds, region: DestinationRegion) =>
    !isEmpty(region.selectedItemsIds) ? { ...ids, [region.id]: region.selectedItemsIds } : ids;
  return {
    [DestinationType.ITINERARIES]: data?.[DestinationType.ITINERARIES].reduce<ItemsIds>(reducer, {}) || {},
    [DestinationType.PORTS_OF_CALL]: data?.[DestinationType.PORTS_OF_CALL].reduce<ItemsIds>(reducer, {}) || {},
  };
};

export const getEmptyItemsIds = () => {
  return getAppliedItemsIds();
};

export const addItemsIds = (
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId: string,
  ids: string[],
) => ({
  ...itemsIds,
  [destinationType]: {
    ...itemsIds[destinationType],
    [regionId]: [...(itemsIds[destinationType]?.[regionId] || []), ...ids],
  },
});

export const setItemsIds = (
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId: string,
  ids: string[],
) => ({
  ...itemsIds,
  [destinationType]: {
    ...itemsIds[destinationType],
    [regionId]: ids,
  },
});

export const removeItemsIds = (
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId: string,
  ids: string[],
) => ({
  ...itemsIds,
  [destinationType]: {
    ...itemsIds[destinationType],
    [regionId]: without(itemsIds[destinationType]?.[regionId], ...ids),
  },
});

export const isItemIdSelected = (
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId: string,
  id: string,
) => Boolean(itemsIds[destinationType]?.[regionId]?.includes(id));

export const getPillState = ({ filterOpened, isMobile }: { filterOpened?: boolean; isMobile: boolean }) => {
  if (filterOpened) {
    return 'open';
  }

  if (isMobile) {
    return 'arrow';
  }

  return 'default';
};

export const getPillLabel = ({ data, hideTitle }: { data: DestinationsFilterData; hideTitle?: boolean }) => {
  let selectedRegions: DestinationRegion[];

  const getSelectedRegions = (destinationType: DestinationType) => {
    return data[destinationType].filter((region) => region.selected && region.selectedItemsIds.length > 0);
  };

  selectedRegions = getSelectedRegions(DestinationType.ITINERARIES);
  if (selectedRegions.length === 0) {
    selectedRegions = getSelectedRegions(DestinationType.PORTS_OF_CALL);
  }

  if (hideTitle) {
    const allRegions =
      selectedRegions.length === 0 || selectedRegions.length === data[DestinationType.ITINERARIES].length;

    return allRegions ? (
      <div className="highlight">
        <UIResource id="ChooseVoyage.Results.AllDestinations" />
        <span className="refinementTextSeparator">,</span>
      </div>
    ) : (
      <div className="highlight">
        {selectedRegions
          .map((region) => region.label)
          .join(' & ')
          .replace(/,(?!.*,)/g, ' &')}
        <span className="refinementTextSeparator">,</span>
      </div>
    );
  }

  if (!selectedRegions.length) {
    return (
      <span className="refinementText">
        <UIResource id="Destination.filter.text" />
      </span>
    );
  }

  return (
    <span className="refinementText">
      {selectedRegions
        .slice(0, 2)
        .map(({ allSelected, label, selectedItemsIds }) =>
          allSelected ? label : `${label} (${selectedItemsIds.length})`,
        )
        .join(', ')}
    </span>
  );
};
