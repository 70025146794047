import { MerchandisingBanner } from '@/components/PromoBanner/Merchandising/MerchandisingBanner';
import { MerchandisingBannerComponents } from '@/components/PromoBanner/Merchandising/hooks/useIsPromoActive';
import UIResource from '@/components/UIResource';
import { selectHeadlineBackground } from '@/ducks/quickSearchItems/selectors';
import { useAppSelector } from '@/store';

import './HeadlineWithAdvertCardList.scss';

type Props = {
  children: React.ReactNode;
};

export const HeadlineWithAdvertCardList = ({ children }: Props) => {
  const background = useAppSelector(selectHeadlineBackground);

  return (
    <div className="HeadlineWithAdvertCardList">
      <div className="heading">
        <div className="heading__wrapper">
          <h2 className="heading__title">
            <UIResource id="BlackFriday.pageTitle" />
          </h2>
        </div>
        <MerchandisingBanner type={MerchandisingBannerComponents.countdownClock} />
      </div>
      <div className="draggable">
        <div className="row">
          <div className="card__container">{children}</div>
        </div>
      </div>

      <div className="step-wave">
        <div className="step-wave--cut-top"></div>
        <span className="step-wave--bg" dangerouslySetInnerHTML={{ __html: background ?? '' }} />
      </div>
    </div>
  );
};
