import { useRouter } from 'next/navigation';

import type { NullablePartial } from '@/types/common';

import { getCookie, setCookie } from '@/ducks/cookies';
import { getRouter } from '@/ducks/routes/history';
import { rootStore } from '@/store';

import { config } from './config';
import { FILTER_KEY, type FilterSetter, type FiltersKey, type FiltersTypes } from './types';
import { getSearchParamsKeys, getSearchParamsValue } from './utils';

type RouterType = ReturnType<typeof useRouter>;

const createSetter =
  <T extends keyof FiltersTypes>(key: T, router?: RouterType): FilterSetter<T> =>
  (value) => {
    setFilters({ [key]: value } as Partial<FiltersTypes>, router);
  };

export const createSetters = (router?: RouterType) => {
  let setters = {} as { [T in FiltersKey]: FilterSetter<T> };
  for (const key in FILTER_KEY) {
    setters = { ...setters, [key]: createSetter(key as FiltersKey, router) };
  }
  return setters;
};

export const resetFilters = (keys: FiltersKey | FiltersKey[]) => {
  setFilters((Array.isArray(keys) ? keys : [keys]).reduce((values, key) => ({ ...values, [key]: null }), {}));
};

export const resetAllFilters = () => {
  resetFilters((Object.keys(FILTER_KEY) as FiltersKey[]).filter((item) => isNaN(Number(item))));
};

/**
 * WARNING! This function should be triggered only on users' action
 */
export const setFilters = (values: NullablePartial<FiltersTypes>, router?: RouterType) => {
  const searchParams = new URLSearchParams(window.location.search);
  const state = rootStore!.getState();

  (Object.keys(values) as FiltersKey[]).forEach((key) => {
    const { isReadOnly, onValueUpdate, serializeValue } = config[key] || {};
    const value = values[key] ?? null;

    const serializedValue =
      typeof serializeValue === 'function' ? serializeValue!(value, { state }) : value?.toString() ?? null;

    const readonly = typeof isReadOnly === 'function' && isReadOnly(getSearchParamsValue(key, searchParams));

    if (!readonly) {
      if (serializedValue) {
        searchParams.set(getSearchParamsKeys(key)[0]!, serializedValue.toString());
      } else {
        getSearchParamsKeys(key).forEach((key) => searchParams.delete(key));
      }
    }

    if (typeof onValueUpdate === 'function') {
      onValueUpdate!({ getCookie, searchParams, setCookie, state, value });
    }
  });

  (router || getRouter()).push(`?${searchParams.toString()}`, { scroll: false });
};

export const setFilter = createSetters();
