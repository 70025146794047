import { fetchCMSComponent } from '@/helpers/api/app';

import { useEmbeddedContent } from './hooks/useEmbeddedContent';

const CMS_CONTENT_URL = 'rotunda/0';

const BlackFridayRotunda = () =>
  useEmbeddedContent({
    className: 'BlackFridayRotunda',
    fetcher: fetchCMSComponent,
    isInline: true,
    noAuth: true,
    url: CMS_CONTENT_URL,
  });

export default BlackFridayRotunda;
