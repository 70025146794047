import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { selectMetaTypeLabel } from '@/ducks/filters/labels/metaType';
import {
  selectCabins,
  selectCurrencyCode,
  selectIsSuitableCabinType,
  selectPriceType,
  selectSailors,
} from '@/ducks/filters/selectors';
import { selectDefaultGenericCategoryCodes, selectGenericCategoryCodes } from '@/ducks/pages/chooseVoyage/selectors';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { FiltersPriceType } from '@/infra/types/common/filters';

import CabinPrice from '../CabinCard/CabinPrice';
import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

class CabinTypeFilter extends BaseFilter {
  getAmountFromGenericCatergoryCodes = (code) => {
    const { defaultGenericCategoryCodes, genericCategoryCodes } = this.props;
    if (isEmpty(genericCategoryCodes)) {
      const category = defaultGenericCategoryCodes?.find(
        (masterCategory) => masterCategory.genericCategoryCode === code,
      );
      return category;
    }
    const category = genericCategoryCodes?.find((masterCategory) => masterCategory.genericCategoryCode === code);
    return category;
  };

  isDisabled(code) {
    const isAmountPrsnt = this.getAmountFromGenericCatergoryCodes(code);
    return !!isAmountPrsnt;
  }

  render() {
    const {
      cabinTypes,
      cabins,
      closeFilterModal,
      currencyCode,
      isOpenFilterSection,
      isSuitableCabinType,
      label,
      onCabinTypeChange,
      priceType,
      sailors,
      selectedType,
    } = this.props;
    const { formatMessage } = this.context;
    let defaultVal = formatMessage({
      defaultMessage: 'Any — the best value available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    [defaultVal] = defaultVal;
    const cabinText = formatMessage({
      defaultMessage: 'Cabin type',
      id: 'AdvancedFilter.CabinType',
    });

    return (
      <div className="AF__row CabinTypeFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          defaultValue={label}
          filterName={cabinText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="cabinType-list action">
            {cabinTypes &&
              cabinTypes.map((meta) => (
                <div
                  className="CabinTypeRefinement__card"
                  key={meta.code}
                  onClick={() => {
                    if (selectedType !== meta.code && !isSuitableCabinType) {
                      onCabinTypeChange(meta.code);
                    }
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
                    <FormCheck
                      checked={selectedType === meta.code}
                      disabled={isSuitableCabinType}
                      id={meta.code}
                      inputTabIndex={0}
                      name={meta.name}
                      role="presentation"
                      tabIndex={-1}
                      type="radio"
                      value={meta.code}
                    />
                  </div>
                  <div className="CabinTypeRefinement__cardImage">
                    {meta.imageUrl && (
                      <div className="CabinTypeRefinement__image">
                        <img alt="CabinTypeRefinement-img" aria-hidden src={meta?.imageUrl?.src} />
                      </div>
                    )}

                    <div className="CabinTypeRefinement__cardDesc">
                      <span
                        className={classNames('CabinTypeRefinement__name', { selected: selectedType === meta.code })}
                      >
                        {meta.name}
                      </span>

                      {this.isDisabled(meta.code) && !(this.isDisabled(meta.code) ? false : isSuitableCabinType) ? (
                        <p className="price">
                          <FormattedMessage defaultMessage="From" id="AdvancedFilter.CabinType.from" />{' '}
                          <CabinPrice
                            abTestPriceType={
                              priceType === FiltersPriceType.sailorPerNight ? FiltersPriceType.perSailor : priceType
                            }
                            cabins={cabins}
                            currencyCode={currencyCode}
                            isFromAdvFilter
                            sailors={sailors}
                            startingPrice={this.getAmountFromGenericCatergoryCodes(meta.code)}
                          />{' '}
                          {priceType === FiltersPriceType.perCabin ? (
                            <FormattedMessage defaultMessage="per cabin" id="AdvancedFilter.CabinType.perCabin.type" />
                          ) : (
                            <FormattedMessage
                              defaultMessage="per sailor"
                              id="AdvancedFilter.CabinType.perSailor.type"
                            />
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="CabinTypeRefinement__card default"
              onClick={() => {
                if (selectedType !== null && selectedType !== defaultVal) {
                  onCabinTypeChange(null);
                }
              }}
              role="button"
              tabIndex={-1}
            >
              <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
                <FormCheck
                  checked={selectedType === defaultVal || selectedType === null}
                  id="default"
                  inputTabIndex={0}
                  name="default"
                  role="presentation"
                  tabIndex={-1}
                  type="radio"
                  value={formatMessage({
                    defaultMessage: 'Any — the best value available',
                    id: 'AdvancedFilter.cabintype.Default.value',
                  })}
                />
              </div>
              <p
                className={`CabinTypeRefinement__description default ${selectedType === defaultVal || selectedType === null ? 'selected' : 'not-selected'}`}
              >
                <FormattedMessage
                  defaultMessage="Any â€” the best value available"
                  id="AdvancedFilter.cabintype.Default.value"
                />
              </p>
            </div>
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }
}

CabinTypeFilter.propTypes = {
  cabinTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cabins: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeFilterModal: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  defaultGenericCategoryCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  genericCategoryCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  isSuitableCabinType: PropTypes.string.isRequired,
  onCabinTypeChange: PropTypes.func.isRequired,
  onRenderChange: PropTypes.func.isRequired,
  priceType: PropTypes.string.isRequired,
  sailors: PropTypes.number.isRequired,
  selectedType: PropTypes.string.isRequired,
};

CabinTypeFilter.defaultProps = {
  isOpenFilterSection: false,
};

CabinTypeFilter.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  cabins: selectCabins(state),
  currencyCode: selectCurrencyCode(state),
  defaultGenericCategoryCodes: selectDefaultGenericCategoryCodes(state),
  genericCategoryCodes: selectGenericCategoryCodes(state),
  isSuitableCabinType: selectIsSuitableCabinType(state),
  label: selectMetaTypeLabel(state),
  priceType: selectPriceType(state),
  sailors: selectSailors(state),
});

export default connect(mapStateToProps)(CabinTypeFilter);
