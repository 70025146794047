import { type HTMLAttributes, type ReactNode } from 'react';

import cn from 'classnames';

import Alert from '@publicImages/svg/alert.svg?static';
import Exclamation from '@publicImages/svg/exclamation.svg?static';
import Tick from '@publicImages/svg/tick.svg?static';

import { WarningType } from '@/features/warnings/types';

import styles from './WarningBoxView.module.scss';

const ICONS = {
  [WarningType.ERROR]: Alert,
  [WarningType.INFO]: Tick,
  [WarningType.WARNING]: Exclamation,
};

type TProps = HTMLAttributes<HTMLDivElement> & {
  text?: ReactNode;
  title: ReactNode;
  titleIcon?: ReactNode;
  type?: WarningType;
};

const WarningBoxView = ({ className, text, title, titleIcon, type, ...restRootProps }: TProps) => {
  const safeType = type ?? WarningType.WARNING;
  const Icon = ICONS[safeType];
  return (
    <div {...restRootProps} className={cn(styles.root, className, `_${safeType}`)} role="alert" tabIndex={0}>
      <span aria-hidden className={cn(styles.rootIcon)}>
        <Icon />
      </span>
      <h3 className={cn(styles.title)}>
        {title}
        {titleIcon}
      </h3>
      <div className={cn(styles.text)}>{text}</div>
    </div>
  );
};

export default WarningBoxView;
