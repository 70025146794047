import Link from 'next/link';
import React, { forwardRef } from 'react';

import cn from 'classnames';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { updateSailingData } from '@/actions/voyagePlanner/summary/sailingData';
import { ViewMoreDatesFlyout } from '@/components/ChooseVoyage/ViewMoreDates';
import UIResource from '@/components/UIResource';
import { Button, CallToAction } from '@/components/elements';
import { routes } from '@/ducks/routes';
import { useKeepSearch } from '@/hooks/router/useKeepSearch';
import { useAppDispatch } from '@/store';

import PortMessage, { PORT_MESSAGE_FORMATS } from '../../PortMessage';
import PackageCardMedia from '../PackageCardMedia/PackageCardMedia';
import PackageCardTextContent from '../PackageCardTextContent/PackageCardTextContent';

import './PackageCard.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  index?: number;
  onFullCruiseButtonClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onSailingClick?: (sailing: SailingFromPackages) => void;
  onSailingFromViewMoreDatesClick?: (sailing: SailingFromPackages) => void;
  onViewMoreDatesClick?: () => void;
  packageData: FilteredPackage;
};

const PackageCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      index = 0,
      onFullCruiseButtonClick,
      onSailingClick,
      onSailingFromViewMoreDatesClick,
      onViewMoreDatesClick,
      packageData,
    },
    ref,
  ) => {
    const dispatch = useAppDispatch();

    const sailing = packageData.bestPriceSailing;
    const sailingsAmount = packageData.sortedSailings.length;

    const handleFullCruiseButton: typeof onFullCruiseButtonClick = (event) => {
      dispatch(updateSailingData(sailing));
      onFullCruiseButtonClick?.(event);
    };

    const fullCruiseHref = useKeepSearch({
      pathname: routes.planner.fullCruiseDetails.path,
      query: {
        packageCode: packageData.packageCode,
      },
    });

    return (
      <div className={cn('PackageCard', className)} data-id={packageData.packageCode} ref={ref}>
        <PackageCardMedia
          fullCruiseButtonSlot={
            <Button asChild className="cruiseBtn -white" variant="secondary">
              <Link href={fullCruiseHref} onClick={handleFullCruiseButton}>
                <UIResource id="VoyageCard.bookNow.CTA" />
              </Link>
            </Button>
          }
          index={index}
          packageData={packageData}
        />
        {children}
        <PackageCardTextContent onSailingClick={onSailingClick} packageData={packageData}>
          <ViewMoreDatesFlyout
            ctaSlot={
              <Button asChild fullWidth variant="secondary">
                <Link href={fullCruiseHref} onClick={handleFullCruiseButton}>
                  <UIResource id="VoyageCard.bookNow.CTA" />
                </Link>
              </Button>
            }
            onItemClick={onSailingFromViewMoreDatesClick}
            packageData={packageData}
          >
            <CallToAction className="more-dates-link" onClick={onViewMoreDatesClick}>
              <UIResource
                id="ChooseVoyage.ViewDates.CTA"
                values={{
                  N: sailingsAmount,
                }}
              />
            </CallToAction>
          </ViewMoreDatesFlyout>
        </PackageCardTextContent>
        {packageData.isPortChangeAvailable && (
          <PortMessage className="PackageCard__PortMess" format={PORT_MESSAGE_FORMATS.UNDER_PACKAGE_CARD} />
        )}
      </div>
    );
  },
);

PackageCard.displayName = 'PackageCard';

export default React.memo(PackageCard);
