import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type { TKeyEventGroup } from '@/ducks/a11y/types';
import type { NullablePartial } from '@/types/common';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import FilterDestination from '@/components/Icon/filterDestination';
import { ListBox, ListItem } from '@/components/ListBox';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs';
import UIResource from '@/components/UIResource';
import { Button, FormCheck } from '@/components/elements';
import Filter from '@/components/elements/Filter';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY, type FiltersTypes } from '@/ducks/filters/types';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';
import { setTrackingDestinationFilter, trackResetFilters } from '@/helpers/util/tracking';
import { useAppDispatch, useAppSelector } from '@/store';

import DestinationItem from './DestinationItem';
import DestinationRegion from './DestinationRegion';
import { selectDestinationsFilterData } from './selectors';
import {
  type DestinationCountryItem,
  type DestinationItineraryItem,
  DestinationType,
  type DestinationRegion as Region,
} from './types';
import {
  type AllItemsIds,
  addItemsIds,
  getAppliedItemsIds,
  getCheckedItemsIds,
  getEmptyItemsIds,
  getPillLabel,
  getPillState,
  getSafeId,
  isItemIdSelected,
  removeItemsIds,
  setItemsIds,
  useGetAppliedDestinationType,
} from './utils';

import './DestinationRefinement.scss';

type DestinationRefinementProps = {
  closeFilterModal?: CallableFunction;
  disabled?: boolean;
  filterOpened?: boolean;
  hideTitle?: boolean;
  onEvent?: CallableFunction;
  onRenderChange?: CallableFunction;
  renderMainFilter?: CallableFunction;
};

const DestinationRefinement = (props: DestinationRefinementProps) => {
  const dispatch = useAppDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [showItineraries, setShowItineraries] = useState(false);
  const showRegions = !showItineraries;

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const data = useAppSelector(selectDestinationsFilterData);
  const appliedDestinationType = useGetAppliedDestinationType();

  const getAppliedData = useCallback(() => {
    return {
      appliedDestinationType,
      appliedItemsIds: getAppliedItemsIds(data),
      appliedRegionId:
        data[appliedDestinationType].find((region) => region.selected)?.id || data[appliedDestinationType][0]!.id,
    };
  }, [data, appliedDestinationType]);

  const { appliedItemsIds, appliedRegionId } = useMemo(() => getAppliedData(), [getAppliedData]);

  const [destinationType, setDestinationType] = useState(appliedDestinationType);
  const [selectedRegionId, setSelectedRegionId] = useState(appliedRegionId);
  const [allItemsIds, setAllItemsIds] = useState<AllItemsIds>(appliedItemsIds);

  const getRegions = useCallback((type?: DestinationType) => data[type || destinationType], [data, destinationType]);
  const getRegion = useCallback(
    (id?: string, type?: DestinationType) => {
      const regions = getRegions(type);
      return id ? regions.find((region) => region.id === id)! : regions[0]!;
    },
    [getRegions],
  );
  const selectedRegion = useMemo(() => getRegion(selectedRegionId), [getRegion, selectedRegionId]);

  const resetToAppliedData = useCallback(() => {
    const { appliedDestinationType, appliedItemsIds, appliedRegionId } = getAppliedData();
    setDestinationType(appliedDestinationType);
    setSelectedRegionId(appliedRegionId);
    setAllItemsIds(appliedItemsIds);
  }, [getAppliedData]);

  const onRegionCheck = useCallback(
    (regionId: string) => {
      const region = getRegion(regionId);
      if (!isEmpty(allItemsIds[destinationType]?.[regionId])) {
        setAllItemsIds(setItemsIds(allItemsIds, destinationType, regionId, []));
      } else {
        setAllItemsIds(setItemsIds(allItemsIds, destinationType, regionId, region.allItemsIds));
      }
    },
    [allItemsIds, destinationType, getRegion],
  );

  const onRegionSelect = useCallback(
    (regionId: string) => {
      setSelectedRegionId(regionId);
      if (isMobile) {
        setShowItineraries(true);
      }
    },
    [isMobile],
  );

  const onItemsClose = useCallback(() => {
    setShowItineraries(false);
  }, []);

  const onDestinationTypeChange = useCallback((value: string) => {
    setDestinationType(value as DestinationType);
  }, []);

  const onItemClick = useCallback(
    (itemId: string) => {
      if (isItemIdSelected(allItemsIds, destinationType, selectedRegionId, itemId)) {
        setAllItemsIds(removeItemsIds(allItemsIds, destinationType, selectedRegionId, [itemId]));
      } else {
        setAllItemsIds(addItemsIds(allItemsIds, destinationType, selectedRegionId, [itemId]));
      }
    },
    [allItemsIds, destinationType, selectedRegionId],
  );

  const safeSelectedRegionId = getSafeId(selectedRegionId);

  const { closeFilterModal, disabled, filterOpened, hideTitle, onEvent, onRenderChange, renderMainFilter } = props;

  const overridenOnEvent = useCallback(
    (...args: unknown[]) => {
      const locator = '.DestinationRefinement.-newFilter .FilterPopover.filterDestination';
      scrollIntoViewAsync(locator, undefined, { block: 'nearest' }, 300);
      onEvent?.(...args);
    },
    [onEvent],
  );

  const isWholeChecked = useCallback(
    (region: Region, destinationType: DestinationType) => {
      return region && region.allItemsIds.length === getCheckedItemsIds(allItemsIds, region.id, destinationType).length;
    },
    [allItemsIds],
  );

  const isWholeCheckedById = useCallback(
    (regionId: string, destinationType: DestinationType) => {
      const region = getRegion(regionId, destinationType);
      return isWholeChecked(region, destinationType);
    },
    [getRegion, isWholeChecked],
  );

  const onSubmit = useCallback(
    (event: React.UIEvent) => {
      event.stopPropagation();

      const regionsIds = Object.keys(allItemsIds[destinationType] || {}).filter(
        (id) => !isEmpty(allItemsIds[destinationType]?.[id] || []),
      );

      const ids = regionsIds.reduce<string[]>(
        (ids: string[], id: string) => [...ids, ...(allItemsIds[destinationType]?.[id] || [])],
        [],
      );

      let filters: NullablePartial<FiltersTypes> = {
        [FILTER_KEY.destPackages]: destinationType === DestinationType.ITINERARIES ? ids : [],
        [FILTER_KEY.destPorts]: destinationType === DestinationType.PORTS_OF_CALL ? ids : [],
        [FILTER_KEY.destRegions]: regionsIds,
      };

      if (!allItemsIds[destinationType] || isEmpty(allItemsIds[destinationType])) {
        filters = { ...filters, [FILTER_KEY.voyageIds]: null };
      }

      setFilters(filters);

      closeFilterModal?.();
      dispatch(setTrackingDestinationFilter(isWholeCheckedById));
    },
    [isWholeCheckedById, destinationType, closeFilterModal, dispatch, allItemsIds],
  );

  const onRegionsKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        document
          .getElementById(destinationType === DestinationType.ITINERARIES ? 'itineraries-list' : 'ports-of-call-list')
          ?.focus();
      }
    },
    [destinationType],
  );

  const onItemsKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      document.getElementById('regions-list')?.focus();
    }
  }, []);

  const shResetDisplay = useCallback(() => {
    return isEmpty(allItemsIds[destinationType]);
  }, [allItemsIds, destinationType]);

  const onReset = useCallback((data: { resetAll?: boolean }) => {
    if (!isEmpty(data) && !data.resetAll) {
      trackResetFilters('destination');
    }

    setAllItemsIds(getEmptyItemsIds());
  }, []);

  const isApplyDisabled = useCallback(() => {
    return isEqual(getAppliedItemsIds(data), allItemsIds);
  }, [data, allItemsIds]);

  const onDismiss = () => {
    resetToAppliedData();
  };

  const pill = {
    disabled,
    headerIcon: <FilterDestination />,
    icon: true,
    label: getPillLabel({ data, hideTitle }),
    state: getPillState({ filterOpened, isMobile }),
    title: { defaultMessage: 'Destinations', id: 'filters.destination' },
    variant: 'large',
  };

  const popover = {
    ariaLabel: 'Destination',
    bodyclass: '-refinementOpen',
    className: 'DestinationRefinement -newFilter',
    disabled,
    id: 'Destination',
    isApplyDisabled: isApplyDisabled(),
    onDismiss,
    onRenderChange,
    onReset,
    onSubmit,
    open: filterOpened,
    renderMainFilter,
    shResetDisplay: shResetDisplay(),
    title: { defaultMessage: 'Destination', id: 'Destination.heading' },
  };

  const detailsClasses = cn('DestinationRefinement__Details', { reSizeHeight: showItineraries });
  const regionsListClasses = cn('filterRegionsList', { showRegions: showRegions });
  const itemsListClassNames = cn('filterItemsList', { showItineraries: showItineraries });
  const mobileRegionTitleClasses = cn('mobileRegionTitle', { toggle: showItineraries });
  const headerClasses = cn('DestinationRefinement__Header', { reSizeHeight: showItineraries });
  const toggleClasses = cn('DestinationRefinement__filterToggle', { toggle: showItineraries });

  return (
    <Filter
      applyNewFilterUI
      focusTrapDisableEvents={['x-arrows', 'y-arrows'] as TKeyEventGroup[]}
      hasFocusTrap
      keepPopoverReferenceFocusAfterClose
      label="Destination Options"
      newFilterDestination
      onEvent={overridenOnEvent}
      pill={pill}
      popover={popover}
      showItineraries={showItineraries}
      tabIndex={0}
      toggleAdvancedRefinement={onItemsClose}
    >
      <div className="DestinationRefinement">
        <section className={headerClasses}>
          <div className="filterDescription">
            <div className="destinationTitle">
              <span className="icon">
                <FilterDestination />
              </span>
              <span className="title">
                <UIResource id="DestinationRefinement.Book" />
              </span>
            </div>

            <div className={mobileRegionTitleClasses}>
              <span className="regionTitleMobile">{selectedRegion?.label}</span>
              <Button
                aria-label="Go back"
                className="filterIcon"
                disableBtnClass
                onClick={onItemsClose}
                tabIndex={showItineraries ? 0 : -1}
              >
                <ArrowLeft />
              </Button>
              <div
                className={cn('allItineraryCheck', {
                  active:
                    !isWholeChecked(selectedRegion, destinationType) &&
                    getCheckedItemsIds(allItemsIds, selectedRegionId, destinationType).length > 0,
                })}
              >
                <FormCheck
                  checked={isWholeChecked(selectedRegion, destinationType)}
                  id={`ALL-${selectedRegionId}`}
                  name="allCheck"
                  onChange={() => onRegionCheck(selectedRegionId)}
                  tabIndex={showItineraries ? 0 : -1}
                  type="checkbox"
                >
                  <span className="allItineraryCheckText">
                    <UIResource
                      id="Destination.allCheckbox"
                      values={{
                        count: selectedRegion?.allItemsIds.length,
                        toggleValue: destinationType,
                      }}
                    />
                  </span>
                </FormCheck>
              </div>
            </div>
          </div>
        </section>

        <Tabs className={detailsClasses} onValueChange={onDestinationTypeChange} value={destinationType}>
          <TabsList className={toggleClasses} tabIndex={-1}>
            <TabsTrigger
              tabIndex={destinationType === DestinationType.ITINERARIES ? 0 : -1}
              value={DestinationType.ITINERARIES}
            >
              <UIResource id="RegionRefinements.itineraries" />
            </TabsTrigger>
            <TabsTrigger
              tabIndex={destinationType === DestinationType.PORTS_OF_CALL ? 0 : -1}
              value={DestinationType.PORTS_OF_CALL}
            >
              <UIResource id="RegionRefinements.port" />
            </TabsTrigger>
          </TabsList>

          <div className={regionsListClasses}>
            <div className="filterTitle">
              <UIResource id="Book CTA" />
            </div>
            <ListBox
              aria-controls="itineraries-list ports-of-call-list"
              id="regions-list"
              onKeyDown={onRegionsKeyDown}
              onPressEnterItem={onRegionCheck}
              onSelectItem={onRegionSelect}
              value={selectedRegionId}
            >
              {getRegions().map((region) => (
                <ListItem key={`region ${region.id} ${destinationType}`} value={region.id}>
                  <DestinationRegion
                    checkedIds={getCheckedItemsIds(allItemsIds, region.id, destinationType)}
                    id={`dest_region_${getSafeId(region.id)}_${destinationType}`}
                    isWholeChecked={isWholeChecked(region, destinationType)}
                    onCheck={onRegionCheck}
                    region={region}
                    selected={selectedRegionId === region.id}
                  />
                </ListItem>
              ))}
            </ListBox>
          </div>

          <TabsContent className={itemsListClassNames} tabIndex={-1} value={DestinationType.ITINERARIES}>
            <div className="filterTitle" id="itineraries-list-label">
              {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.itineraries" />
            </div>
            <ListBox
              aria-labelledby="itineraries-list-label"
              id="itineraries-list"
              onKeyDown={onItemsKeyDown}
              onPressEnterItem={onItemClick}
            >
              {(selectedRegion.items as DestinationItineraryItem[]).map((item, index) => (
                <ListItem key={`${selectedRegion.id} ${item.id} - ${index}`} value={item.id}>
                  <DestinationItem
                    checked={isItemIdSelected(allItemsIds, destinationType, selectedRegion.id, item.id)}
                    id={`item-${safeSelectedRegionId}-${item.id}`}
                    item={item}
                    onClick={onItemClick}
                  />
                </ListItem>
              ))}
            </ListBox>
          </TabsContent>

          <TabsContent className={itemsListClassNames} tabIndex={-1} value={DestinationType.PORTS_OF_CALL}>
            <div className="filterTitle" id="ports-of-call-list-label">
              {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.ports" />
            </div>
            <ListBox
              aria-labelledby="ports-of-call-list-label"
              id="ports-of-call-list"
              onKeyDown={onItemsKeyDown}
              onPressEnterItem={onItemClick}
            >
              {(selectedRegion.items as DestinationCountryItem[]).map((country) => (
                <React.Fragment key={`${selectedRegion.id} ${country.label}`}>
                  <div aria-hidden="true" className="countryName">
                    {country.label}
                  </div>
                  {country.items?.map((item, index) => (
                    <ListItem
                      className={cn({ firstPort: index === 0 })}
                      key={`${selectedRegion.id} ${item.id} ${country.label}`}
                      value={item.id}
                    >
                      <DestinationItem
                        checked={isItemIdSelected(allItemsIds, destinationType, selectedRegion.id, item.id)}
                        id={`item-${safeSelectedRegionId}-${item.id}`}
                        item={item}
                        onClick={onItemClick}
                      />
                    </ListItem>
                  ))}
                </React.Fragment>
              ))}
            </ListBox>
          </TabsContent>
        </Tabs>
      </div>
    </Filter>
  );
};

export default DestinationRefinement;
