import { useMemo } from 'react';

import isNil from 'lodash/isNil';

import type { TPromotionAddonProps } from '@/ducks/promoBanners/types';

export const useGetBarTabAmount = (duration: number, promotionAddons?: Array<TPromotionAddonProps>): string => {
  return useMemo(
    () =>
      promotionAddons?.find(
        ({ max, min }: TPromotionAddonProps) => (isNil(min) || duration >= min) && (isNil(max) || duration <= max),
      )?.name || '',
    [duration, promotionAddons],
  );
};
