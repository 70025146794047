import React, { useCallback } from 'react';

import { useMainFilterHandlers } from '@/components/ChooseVoyage/MainFilter/hooks';
import Filter from '@/components/Icon/FilterOption';
import DateRefinement from '@/components/ResultRefinements/DateRefinement';
import DestinationRefinement from '@/components/ResultRefinements/DestinationRefinement';
import { selectDestinationsFilterData } from '@/components/ResultRefinements/DestinationRefinement/selectors';
import { getPillLabel } from '@/components/ResultRefinements/DestinationRefinement/utils';
import { Skeleton } from '@/components/Skeleton';
import UIResource from '@/components/UIResource';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { reducedMonthAndYear } from '@/helpers/util/dateUtil';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import './FilterView.scss';

type FilterViewForSMProps = {
  closeFilterModal: () => void;
  openFilterModal: () => void;
};

const FilterViewForSM = ({ closeFilterModal, openFilterModal }: FilterViewForSMProps) => {
  const appliedEndDate = useAppSelector(selectToDate);
  const appliedStartDate = useAppSelector(selectFromDate);
  const filteredSailings = useAppSelector(selectFilteredSailings) || [];
  const filteredSailingsLoading = useAppSelector(selectIsFilteringLoading);

  const data = useAppSelector(selectDestinationsFilterData);
  const label = getPillLabel({ data, hideTitle: true });

  const onDestinationFilterClick = useCallback(
    (isOpened: boolean) => {
      if (!isOpened) {
        return;
      }
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.DESTINATIONS,
        value: label,
      });
    },
    [label],
  );

  const onDateFilterClick = useCallback(
    (isOpened: boolean) => {
      if (!isOpened) {
        return;
      }
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.TRAVEL_DATES,
        value: `${reducedMonthAndYear(appliedStartDate)}_${reducedMonthAndYear(appliedEndDate)}`,
      });
    },
    [appliedStartDate, appliedEndDate],
  );

  const { dateFilterOpened, destinationFilterOpened, setDateFilterOpenedHandler, setDestinationFilterOpenedHandler } =
    useMainFilterHandlers({
      onDateFilterStatusChange: onDateFilterClick,
      onDestinationFilterStatusChange: onDestinationFilterClick,
    });

  const onKeyDownFilter = useOnKeyDown(openFilterModal, { isDefaultPrevented: true });

  return (
    <>
      <div className="FilterText">
        <div className="heading --mobile">
          <div className="refinementText">
            <span
              aria-label="Showing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <UIResource id="ChooseVoyage.Results.showing" />
            </span>
            <span className="text action">
              <span className="sailingsCountText">
                {filteredSailingsLoading ? <Skeleton inline width="22px" /> : filteredSailings.length}
              </span>
            </span>
            <span
              aria-label="cruise vacations sailing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <UIResource id="ChooseVoyage.Results.cruise.vacations.name" />:
            </span>
            &nbsp;
            <span className="text action">
              <span className="regionsText">
                <DestinationRefinement
                  closeFilterModal={closeFilterModal}
                  filterOpened={destinationFilterOpened}
                  hideTitle
                  onRenderChange={setDestinationFilterOpenedHandler}
                />
              </span>
            </span>
            &nbsp;
            <span className="text action">
              <span className="refinement">
                <DateRefinement filterOpened={dateFilterOpened} hideTitle onRenderChange={setDateFilterOpenedHandler} />
              </span>
            </span>
          </div>
        </div>
        <div
          aria-label="filter"
          className="filterIconMobile"
          onClick={openFilterModal}
          onKeyDown={onKeyDownFilter}
          role="button"
          tabIndex={0}
        >
          <Filter />
        </div>
      </div>
    </>
  );
};

export default FilterViewForSM;
