import PropTypes from 'prop-types';
import React from 'react';

import { connect, useSelector } from 'react-redux';

import AdvancedSelectionSkeleton from '@/components/ChooseVoyage/AdvancedSelection/Skeleton';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { FormattedMessage } from '@/helpers/formatted-message';

import SortPriceType from '../SortPriceType';
import SortRefinementForPackages from '../SortRefinementForPackages';

import './AdvancedSelection.scss';

const AdvancedSelection = (props) => {
  const { sailings = [] } = props;

  const isLoading = useSelector(selectIsFilteringLoading);

  return isLoading ? (
    <AdvancedSelectionSkeleton />
  ) : (
    <section className="AdvancedSelection">
      <div aria-live="polite" className="AdvancedSelection__text" role="status" tabIndex="0">
        <FormattedMessage defaultMessage="Showing" id="ChooseVoyage.Results.showing" />
        <b>
          <FormattedMessage
            defaultMessage=" {N} cruise vacations"
            id="ChooseVoyage.Results.number"
            values={{ N: sailings.length }}
          />
        </b>
        <FormattedMessage defaultMessage=" that match your search" id="ChooseVoyage.Results.match" />
      </div>
      <div className="AdvancedSelection__action">
        <div className="sortDropdown">
          <SortPriceType />
        </div>
        <div className="sortDropdown">
          <SortRefinementForPackages />
        </div>
      </div>
    </section>
  );
};

AdvancedSelection.propTypes = {
  sailings: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      id: PropTypes.string,
      packageCode: PropTypes.string,
      startDate: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

const mapStateToProps = (state) => ({
  sailings: selectFilteredSailings(state),
});

export default connect(mapStateToProps)(AdvancedSelection);
