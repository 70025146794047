'use client';

import Countdown from '@/components/Countdown';
import { selectMerchandisingBannerDetails } from '@/ducks/promoBanners/selectors';
import { useAppSelector } from '@/store';

import './style.scss';

// NOTE: Once all ticket of BF is completed Files under component/BlackFriday  can be removed.

type props = {
  className?: string;
};

export const PromotionTimer = ({ className = 'PromotionStopWatch' }: props) => {
  const merchandisingBanner = useAppSelector(selectMerchandisingBannerDetails);

  const countDown = merchandisingBanner?.countdown;

  if (!isCountdownDataValid(countDown)) {
    return null;
  }

  return (
    <div className={className}>
      <Countdown className={`${className}__countdown`} frequency={3} targetTime={countDown!} />
    </div>
  );
};

const isCountdownDataValid = (countdown: string | undefined) => {
  if (!countdown) {
    return false;
  }
  const endDate = new Date(countdown);
  const currentDate = new Date();
  if (endDate <= currentDate) {
    return false;
  }
  return true;
};
